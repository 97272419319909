<template>
  <div class="link-wrapper">
    <a @click="$emit('secondLevel','about')"> <img src="@/img/H5/about.png" alt=""/> 关于我们 </a>
    <a @click="$emit('secondLevel','disclaimer')"> <img src="@/img/H5/disclaimer.png" alt=""/> 免责声明 </a>
  </div>
</template>

<script>

export default {
  name: 'About',
}

</script>

<style scoped lang="scss">

.link-wrapper{
  padding-top:70px;
}
a{
  display:flex;
  align-items:center;
  display: -webkit-flex;
  -webkit-align-items: center;
  background:#fff;
  margin-bottom:12px;
  padding:8px 10px;
  font-size:14px;
  color:#3d3d3d;
  position:relative;
  img{margin:0 5px;}
  &:after{
    background: url('~@/img/H5/arrow.png') no-repeat center;
    transform:rotate(-90deg);
    width: 20px;
    height: 20px;
    content: '';
    display: block;
    position: absolute;
    right: 10px;
    top: 8px;
  }
}
</style>

